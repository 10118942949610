import { modeOptions } from "../constants";
import { Link, useLocation } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import classNames from "classnames";

export const ModeSelector: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsOpen(false);
      buttonRef.current?.focus();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md px-4 py-2 border dark:border-white text-zinc-200 dark:text-zinc-300 hover:bg-zinc-700 focus:outline-none text-sm"
          id="options-menu"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={() => setIsOpen(!isOpen)}
          ref={buttonRef}
        >
          {modeOptions.find((option) => option.path === pathname)?.label || "Select Mode"}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <nav
          className="origin-top-right absolute z-50 right-0 mt-2 w-56 rounded-md dark:bg-zinc-800 dark:bg-opacity-50 backdrop-blur-md dark:text-white shadow-xl"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
          ref={menuRef}
          onKeyDown={handleKeyDown}
        >
          <ul className="py-1" role="none">
            <li>
              {modeOptions.map((option) => {
                const className = classNames(
                  "block px-4 py-2 text-sm text-gray-700 dark:text-white hover:bg-zinc-600 w-full text-left",
                  {
                    "bg-zinc-700": option.path === pathname,
                  }
                );
                return (
                  <Link to={option.path} key={option.mode} className={className}>
                    {option.label}
                  </Link>
                );
              })}
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};
