import { TableCell } from "../components";
import { useContext, useMemo } from "react";
import { fixed, sign } from "src/functions";
import { GlobalContext } from "src/context/global";
import { ITargetTempoOptions } from "src/types.d";

interface TargetTempoOptionsProps {
  exact: number;
}

export const TargetTempoOptions: React.FC<TargetTempoOptionsProps> = ({ exact }) => {
  const { tempo } = useContext(GlobalContext);

  const options: ITargetTempoOptions = useMemo(() => {
    if (!tempo) {
      return {
        higher: undefined,
        lower: undefined,
      };
    }
    const roundedUpSemitones = Math.ceil(exact);
    const roundDownSemitones = Math.floor(exact);

    return {
      higher:
        roundedUpSemitones === 0
          ? undefined
          : {
              tempo: fixed(Math.pow(2, roundedUpSemitones / 12) * tempo),
              semitoneShift: roundedUpSemitones,
            },
      lower:
        roundDownSemitones === 0 || roundDownSemitones === roundedUpSemitones
          ? undefined
          : {
              tempo: fixed(Math.pow(2, roundDownSemitones / 12) * tempo),
              semitoneShift: roundDownSemitones,
            },
    };
  }, [tempo, exact]);

  return (
    <tbody>
      {/* Show the lower possibility */}
      {options.lower && (
        <tr>
          <TableCell mono>{options.lower.tempo.toFixed(2)}</TableCell>
          <TableCell mono>{sign(options.lower.semitoneShift)}</TableCell>
        </tr>
      )}

      {/* Show the higher possibility */}
      {options.higher && (
        <tr>
          <TableCell mono>{options.higher.tempo.toFixed(2)}</TableCell>
          <TableCell mono>{sign(options.higher.semitoneShift)}</TableCell>
        </tr>
      )}
    </tbody>
  );
};
