import { Screen } from "./Screen";
import { columnMap } from "src/constants";
import { GlobalContext } from "src/context/global";
import { useContext, useMemo, useState } from "react";
import { ColumnBpmToMs, Buttons, TempoInput, Intro } from "../components";
import { ColumnBpmToHz } from "src/components/ColumnBpmToHz";

export const BpmToHz: React.FC = () => {
  const [roundValues, setRoundValues] = useState(false);
  const [index, setIndex] = useState(0);

  const { tempo } = useContext(GlobalContext);

  // Make sure we have a tempo before showing buttons & table
  const noTempo = useMemo(() => isNaN(Number(tempo)) || !tempo || tempo <= 10, [tempo]);

  // Which column to show?
  const { factor } = columnMap[index];

  return (
    <Screen>
      <div className="my-8">
        <TempoInput autoFocus />
      </div>

      {noTempo && (
        <Intro text="Calculates frequency values from a tempo for various note lengths. You can choose between straight, dotted and triplet notes." />
      )}

      {!noTempo && tempo && (
        <div className="flex flex-col gap-8">
          <Buttons
            items={["Straight", "Dotted", "Triplets"]}
            value={index}
            setValue={setIndex}
          />
          <ColumnBpmToHz tempo={tempo} factor={factor} />
        </div>
      )}
    </Screen>
  );
};
