import { Screen } from "./Screen";
import { useContext } from "react";
import { GlobalContext } from "src/context/global";
import { Intro, NumericInput } from "../components/";
import { ColumnMsToBpm } from "../components/ColumnMsToBpm";

export const MsToBpm: React.FC = () => {
  const { ms, setMs } = useContext(GlobalContext);

  return (
    <Screen>
      <div className="my-8">
        <NumericInput
          autoFocus
          value={ms || ""}
          setValue={(value) => setMs(Number(value))}
          label="ms"
          placeholder="ms"
        />
      </div>
      {ms && (
        <div className="content">
          <ColumnMsToBpm ms={Number(ms)} />
        </div>
      )}
      {!ms && (
        <Intro
          text="Given a millisecond value, this will tell you possible tempos that match. For
            example, if you enter 250ms that would give a tempo of 120bpm if you were
            counting 1/8th notes, but 60bpm for 1/16th notes."
        />
      )}
    </Screen>
  );
};
