import { Screen } from "./Screen";
import { Buttons } from "../components";
import { GlobalContext } from "src/context/global";
import { useCallback, useContext, useMemo } from "react";
import { Table, TableHeader, TableCell } from "../components";
import { enharmonicOptions, TWO_TWELFTH_ROOT } from "../constants";

export const Notes: React.FC = () => {
  const { octave, setOctave, enharmonicShift, setEnharmonicShift } =
    useContext(GlobalContext);

  const notes = useMemo(() => enharmonicOptions[enharmonicShift], [enharmonicShift]);

  const toggleEnharmonic = useCallback(() => {
    setEnharmonicShift(enharmonicShift === "sharp" ? "flat" : "sharp");
  }, [enharmonicShift, setEnharmonicShift]);

  const rows = useMemo(() => {
    return notes.map((note, i) => {
      const notePattern = /([A-G])([#b]?)/;
      const rootNote = note.match(notePattern)!;
      const noteDisplay = (
        <span>
          {rootNote[1]}
          <sup>{rootNote[2]}</sup>
        </span>
      );

      const midiNoteNumber = 12 + octave * 12 + i;
      if (midiNoteNumber > 127) {
        return null;
      }

      const distToA440 = midiNoteNumber - 69;
      let hz = distToA440 === 0 ? 440 : 440 * Math.pow(TWO_TWELFTH_ROOT, distToA440);
      const ms = 1000 / hz;

      return (
        <tr key={i}>
          <TableCell>
            <button onClick={toggleEnharmonic}>
              {noteDisplay}
              {octave}
            </button>
          </TableCell>
          <TableCell mono>{midiNoteNumber}</TableCell>
          <TableCell mono>{hz.toFixed(2)}</TableCell>
          <TableCell mono>{ms.toFixed(2)}</TableCell>
        </tr>
      );
    });
  }, [notes, octave, toggleEnharmonic]);

  const buttonItems = Array.from(Array(10).keys()).map((i) => i.toString());

  return (
    <Screen>
      <div className="my-8">
        <p className="text-xs text-center py-1">OCTAVE</p>
        <Buttons value={octave} setValue={setOctave} items={buttonItems} />
      </div>

      <Table>
        <thead>
          <tr>
            <TableHeader>Note</TableHeader>
            <TableHeader>MIDI</TableHeader>
            <TableHeader>Hz</TableHeader>
            <TableHeader>ms</TableHeader>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      <p className="mt-4 text-balance">
        To convert from sharps to flats view, just tap on any note name on the left
      </p>
    </Screen>
  );
};
