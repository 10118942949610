import { Screen } from "./Screen";
import { useContext } from "react";
import { Intro, NumericInput } from "../components";
import { GlobalContext } from "src/context/global";

export const HzToMs: React.FC = () => {
  const { hz, setHz } = useContext(GlobalContext);

  return (
    <Screen>
      <div className="my-8">
        <NumericInput
          autoFocus
          value={hz || ""}
          setValue={(value) => setHz(Number(value))}
          label="Hz"
          placeholder="Hz"
        />
      </div>
      <div className="content">
        {hz !== undefined && hz > 0 && (
          <span className="text-2xl">{Math.round((1000 / hz) * 10000) / 10000}ms</span>
        )}
        {(!hz || hz <= 0) && (
          <Intro
            text="Converts frequency to milliseconds - good for tuning flangers and chorus
            effects, and creating tones from pure delays."
          />
        )}
      </div>
    </Screen>
  );
};
