import "./styles/index.css";
import AppRouter from "./AppRouter";
import { GlobalProvider } from "./context/global";

function App() {
  return (
    <GlobalProvider>
      <AppRouter />
    </GlobalProvider>
  );
}

export default App;
