import React from "react";

interface IntroProps {
  text: string;
}
export const Intro: React.FC<IntroProps> = ({ text }) => {
  return (
    <div className="intro xl:text-2xl text-balance">
      <p>{text}</p>
    </div>
  );
};