import { Screen } from "./Screen";
import { useContext } from "react";
import { Intro, NumericInput } from "../components";
import { GlobalContext } from "src/context/global";
import { ColumnMsToHz } from "../components/ColumnMsToHz";

export const MsToHz = () => {
  const { ms, setMs } = useContext(GlobalContext);

  return (
    <Screen>
      <div className="my-8">
        <NumericInput
          autoFocus
          value={ms}
          setValue={(value) => setMs(Number(value))}
          label="ms"
          placeholder="ms"
        />
      </div>
      <div className="content">
        {ms && ms > 0 && <ColumnMsToHz ms={ms} />}
        {(!ms || ms <= 0) && (
          <Intro text="Converts millisecond values to frequency. Good for working out synced LFOs." />
        )}
      </div>
    </Screen>
  );
};
