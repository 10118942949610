import classNames from "classnames";

interface ButtonsProps {
  value: number;
  setValue: (value: number) => void;
  items: React.ReactNode[] | React.ReactNode[][];
}

export const Buttons: React.FC<ButtonsProps> = ({ value, setValue, items }) => {
  const className = classNames("buttons w-full flex-1 flex", {
    compact: items.length > 4,
  });

  return (
    <div className={className}>
      {items.map((item: React.ReactNode | React.ReactNode[], i: number) => {
        const classNm = classNames("btn border dark:border-zinc-300 flex-1 py-2", {
          "dark:bg-white dark:bg-opacity-20": i === value,
          "rounded-l-full": i === 0,
          "rounded-r-full": i === items.length - 1,
          "border-l-0": i === 1,
          "border-r-0": i === items.length - 2,
        });

        return (
          <button key={i} className={classNm} onClick={() => setValue(i)}>
            {item}
          </button>
        );
      })}
    </div>
  );
};
