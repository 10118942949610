import { IEnharmonicOptions, Mode } from "./types.d";

export const TWO_TWELFTH_ROOT = Math.pow(2, 1 / 12);
export const A_440 = 440;

export const modeOptions = [
  {
    label: "Notes",
    mode: Mode.NOTES,
    path: "/notes",
  },
  {
    label: "BPM to MS",
    mode: Mode.BPM_MS,
    path: "/bpm-to-ms",
  },
  {
    label: "BPM to Hz",
    mode: Mode.BPM_HZ,
    path: "/bpm-to-hz",
  },
  {
    label: "MS to BPM",
    mode: Mode.MS_BPM,
    path: "/ms-to-bpm",
  },
  {
    label: "Tempo to Pitch",
    mode: Mode.TEMPO_PITCH,
    path: "/tempo-pitch",
  },
  {
    label: "MS to Hz",
    mode: Mode.MS_HZ,
    path: "/ms-to-hz",
  },
  {
    label: "Hz to MS",
    mode: Mode.HZ_MS,
    path: "/hz-to-ms",
  },
];

export const columnMap = [
  { title: "Straight", factor: 1 },
  { title: "Dotted", factor: 1.5 },
  { title: "Triplets", factor: 4 / 3 },
];

export const divisions = [1, 2, 4, 8, 16, 32, 64, 128];

export const enharmonicOptions: IEnharmonicOptions = {
  sharp: ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"],
  flat: ["C", "Db", "D", "Eb", "E", "F", "Gb", "G", "Ab", "A", "Bb", "B"],
};
