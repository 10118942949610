import { divisions } from "src/constants";
import { Table, TableCell, TableHeader } from "./Table";

interface ColumnMsToHzProps {
  ms: number;
}

export const ColumnMsToHz: React.FC<ColumnMsToHzProps> = ({ ms }) => {
  const decimalPlaces = 4;
  const f = Math.pow(10, decimalPlaces);

  return (
    <div className="column">
      <Table>
        <thead>
          <tr>
            <TableHeader>n</TableHeader>
            <TableHeader>Hz</TableHeader>
          </tr>
        </thead>
        <tbody>
          {divisions.map((division) => {
            let valueNumeric = Math.round(((1000 * division) / ms) * f) / f;
            let valueDisplay = "";

            if (valueNumeric % 1000 === 0) {
              valueNumeric /= 1000;
              valueDisplay = `${valueNumeric}kHz`;
            } else {
              valueDisplay = valueNumeric.toString();
            }

            return (
              <tr>
                <TableCell mono>{division}</TableCell>
                <TableCell mono>{valueDisplay}</TableCell>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
