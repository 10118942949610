import { Screen } from "./Screen";
import { Intro } from "../components";
import { HomeButton } from "../components/HomeButton";
import { Metronome, Clock, MusicNotesSimple, WaveSine } from "@phosphor-icons/react";

export const Home: React.FC = () => {
  return (
    <Screen>
      <div className="py-12">
        <Intro text="Welcome to Synculator, the Swiss Army Knife for musicians." />

        <div className="grid grid-cols-2 mt-8 gap-4">
          <HomeButton
            IconClass={Metronome}
            to="/bpm-to-ms"
            label="BPM to Milliseconds"
            description="Given a BPM, this will give you delay times for various note lengths"
          />
          <HomeButton
            IconClass={WaveSine}
            to="/bpm-to-hz"
            label="BPM to Hz"
            description="Converts tempo to frequency - useful for LFOs and other frequency-based effects"
          />
          <HomeButton
            IconClass={Clock}
            to="/ms-to-bpm"
            label="Milliseconds to BPM"
            description="Given a millisecond value, this will tell you possible tempos that match various note lengths"
          />
          <HomeButton
            IconClass={MusicNotesSimple}
            to="/notes"
            label="Notes"
            description="A reference for the MIDI note numbers and frequency of musical notes"
          />
          <HomeButton
            IconClass={Metronome}
            to="/tempo-pitch"
            label="Tempo & Pitch"
            description="Find faster / slower / target tempos that give a semitone-accurate pitch"
          />
          <HomeButton
            IconClass={Clock}
            to="/ms-to-hz"
            label="Milliseconds to Hz"
            description="Converts millisecond values to frequency. Good for working out synced LFOs"
          />
          <HomeButton
            IconClass={WaveSine}
            to="/hz-to-ms"
            label="Hz to Milliseconds"
            description="Converts frequency to milliseconds - good for tuning flangers and chorus
            effects, and creating tones from pure delays."
          />
        </div>
      </div>
    </Screen>
  );
};
