import { divisions } from "src/constants";
import { Table, TableCell, TableHeader } from "./Table";

interface ColumnBpmToMsProps {
  tempo: number;
  factor?: number;
  roundValues: boolean;
}

export const ColumnBpmToMs: React.FC<ColumnBpmToMsProps> = ({
  tempo,
  roundValues,
  factor = 1,
}) => {
  return (
    <Table>
      <thead>
        <tr>
          <TableHeader>Note</TableHeader>
          <TableHeader>Delay (ms)</TableHeader>
        </tr>
      </thead>
      <tbody>
        {divisions.map((division, index) => {
          const delayTime = (((60 / tempo) * 1000 * 4) / division) * factor;
          const value = roundValues
            ? Math.round(delayTime)
            : Math.round(delayTime * 100) / 100;

          return (
            <tr key={index}>
              <TableCell mono>1/{division}</TableCell>
              <TableCell mono>{value}</TableCell>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
