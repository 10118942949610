import { Screen } from "./Screen";
import { fixed, sign } from "../functions";
import { PitchTempoMode } from "../types.d";
import { GlobalContext } from "src/context/global";
import { TempoPitchOptions } from "./TempoPitchOptions";
import { TargetTempoOptions } from "./TargetTempoOptions";
import { useState, useContext, useMemo, Fragment } from "react";
import { ArrowDown, Metronome, PlusMinus } from "@phosphor-icons/react";
import { TempoInput, Buttons, Table, TableHeader, Intro } from "../components";

export const PitchAndTempo: React.FC = () => {
  const { tempo } = useContext(GlobalContext);
  const [targetTempo, setTargetTempo] = useState<number | undefined>(undefined);

  // TODO: context / load from local storage
  const [display, setDisplay] = useState<PitchTempoMode>(PitchTempoMode.FASTER);

  const tempoIsValid = useMemo(() => !!tempo && tempo >= 25, [tempo]);
  const targetTempoIsValid = useMemo(
    () => !!targetTempo && targetTempo >= 25,
    [targetTempo]
  );

  // Exact semitone shift to target tempo
  const exactSemitoneShift = useMemo(() => {
    if (!tempo) {
      return undefined;
    }

    if (display === PitchTempoMode.TARGET && targetTempoIsValid) {
      // Work out the exact semitones to the target
      const fraction = targetTempo! / tempo;
      return 12 * Math.log2(fraction);
    }

    return undefined;
  }, [tempo, targetTempo, targetTempoIsValid, display]);

  const showTable = useMemo(() => {
    if (display === PitchTempoMode.TARGET) {
      return targetTempoIsValid;
    }

    return tempoIsValid;
  }, [display, targetTempoIsValid, tempoIsValid]);

  return (
    <Screen>
      <div className="options flex flex-col lg:flex-row gap-8 items-start my-8">
        <div className="flex flex-col gap-2">
          <TempoInput placeholder="Tempo" autoFocus />

          {display === PitchTempoMode.TARGET && (
            <Fragment>
              <ArrowDown size={24} className="ml-12" />
              <TempoInput
                placeholder="Target"
                autoFocus
                setManualTempo={setTargetTempo}
                manualTempo={targetTempo}
              />
            </Fragment>
          )}
        </div>

        {tempoIsValid && (
          <Buttons
            value={display}
            setValue={setDisplay}
            items={["Faster", "Slower", "Target"]}
          />
        )}
      </div>

      {exactSemitoneShift && (
        <div className="my-4 text-xl">
          <p className="mb-4 py-3 px-4 bg-zinc-800 border border-zinc-500 rounded-lg">
            <span className="font-mono">Exact Shift:</span>{" "}
            <span className="font-mono">
              {sign(fixed(Number(exactSemitoneShift), 4))} semitones
            </span>
          </p>
          <h3 className="mt-8 mb-4">Tempos that will work:</h3>
        </div>
      )}

      {showTable && (
        <Table>
          <thead>
            <tr>
              <TableHeader>
                <Metronome />
                Tempo (bpm)
              </TableHeader>
              <TableHeader>
                <PlusMinus />
                Shift (semitones)
              </TableHeader>
            </tr>
          </thead>

          {display === PitchTempoMode.TARGET && exactSemitoneShift && (
            <TargetTempoOptions exact={exactSemitoneShift} />
          )}

          {display !== PitchTempoMode.TARGET && (
            <TempoPitchOptions display={display} tempo={tempo} />
          )}
        </Table>
      )}

      {!tempoIsValid && (
        <Intro
          text="Enter a tempo to see faster / slower tempos that give a semitone-accurate pitch.
          You can also enter a specific target tempo and it will tell you tempos near that
          target that will work to keep your track chromatically related."
        />
      )}
    </Screen>
  );
};
