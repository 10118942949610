import { TableCell } from "src/components";
import { fixed, sign } from "src/functions";
import { PitchTempoMode } from "src/types.d";

interface TempoPitchOptionsProps {
  display: PitchTempoMode;
  tempo?: number;
}

export const TempoPitchOptions: React.FC<TempoPitchOptionsProps> = ({
  display,
  tempo,
}) => {
  return !!tempo ? (
    <tbody>
      {Array.from(Array(24).keys()).map((value, index) => {
        const f = display === PitchTempoMode.FASTER ? 1 : -1;
        return (
          <tr key={`tr${index}`}>
            <TableCell>
              <span className="font-mono">
                {fixed(Math.pow(2, ((1 + value) * f) / 12) * tempo!).toFixed(2)}
              </span>
            </TableCell>
            <TableCell>
              <span className="font-mono">{sign((index + 1) * f)}</span>
            </TableCell>
          </tr>
        );
      })}
    </tbody>
  ) : null;
};
