export const fixed = (input: number, places = 2): number => {
  return Math.round(input * Math.pow(10, places)) / Math.pow(10, places);
};

export const sign = (input: number): string => {
  return input > 0 ? `+${input}` : input.toString();
};

export const clampToDecimalPlaces = (input: number, places = 2): number => {
  return Number(input.toFixed(places));
};
