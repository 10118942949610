import classNames from "classnames";
import { divisions } from "src/constants";
import { clampToDecimalPlaces } from "src/functions";
import { Table, TableCell, TableHeader } from "./Table";
interface ColumnMsToBpmProps {
  ms: number;
}

export const ColumnMsToBpm: React.FC<ColumnMsToBpmProps> = ({ ms }) => {
  return (
    <div className="column">
      <Table>
        <thead>
          <tr>
            <TableHeader>Division</TableHeader>
            <TableHeader>Tempo</TableHeader>
          </tr>
        </thead>
        <tbody>
          {divisions.map((division, index) => {
            // So this time it's in reverse
            const tempo = clampToDecimalPlaces((60 / (ms / 1000)) * (4 / division), 4);

            const trClass = classNames({
              "opacity-30": tempo < 60 || tempo > 180,
            });

            return tempo > 10 && tempo < 300 ? (
              <tr key={index} className={trClass}>
                <TableCell mono>1/{division}</TableCell>
                <TableCell mono>{tempo}</TableCell>
              </tr>
            ) : null;
          })}
        </tbody>
      </Table>
    </div>
  );
};
