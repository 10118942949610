interface TableProps {
  children: React.ReactNode;
}

export const Table: React.FC<TableProps> = ({ children }) => {
  return <table className="table-auto w-full">{children}</table>;
};

// styled thead
interface TableHeaderProps {
  children: React.ReactNode;
}

export const TableHeader: React.FC<TableHeaderProps> = ({ children }) => {
  return (
    <th className="font-normal border dark:border-zinc-600 px-4 py-2 text-left xl:text-xl dark:text-zinc-400 dark:bg-white dark:bg-opacity-5">
      <div className="flex items-center gap-2">{children}</div>
    </th>
  );
};

// Cell
interface TableCellProps {
  mono?: boolean;
  children: React.ReactNode;
}

export const TableCell: React.FC<TableCellProps> = ({ mono, children }) => {
  const fontClass = mono ? "font-mono font-medium" : "font-sans";
  return (
    <td
      className={`border dark:bg-zinc-900 dark:border-zinc-600 px-4 py-2 text-left xl:text-xl ${fontClass}`}
    >
      {children}
    </td>
  );
};
