import { Link } from "react-router-dom";
import { ModeSelector } from "../components";
import { Logo } from "src/components/Logo";

interface ScreenProps {
  children: React.ReactNode | React.ReactNode[];
}
export const Screen: React.FC<ScreenProps> = ({ children }) => (
  <div className="min-h-screen flex flex-col">
    <div className="container max-w-xl mx-auto px-4 lg:px-8 py-4 flex-1">
      <header className="flex justify-between items-center">
        <div className="flex flex-col justify-center">
          <Link
            to="/"
            className="logo text-sm lg:text-base flex gap-2 items-center"
          >
            <Logo className="w-6" />
            <div className="flex flex-col">
              <span className="dark:text-zinc-300">SYNCULATOR</span>
              <span className="text-xs font-thin dark:text-zinc-300">by Stevie J Jones</span>
            </div>
          </Link>
        </div>
        <ModeSelector />
      </header>
      {children}
    </div>
    <footer>
      <div className="container mx-auto px-4 lg:px-8 py-4">
        <p className="text-center dark:text-zinc-400">
          © {new Date().getFullYear()}{" "}
          <a
            href="https://bsky.app/profile/stevejones.music"
            className="underline dark:hover:text-white"
            target="_blank"
            rel="noopener noreferrer"
          >
            Stevie J Jones
          </a>
          . All rights reserved.
        </p>
      </div>
    </footer>
  </div>
);
