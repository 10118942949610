interface LogoProps {
  className?: string;
}
export const Logo: React.FC<LogoProps> = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272.1 399" className={className}>
      <defs>
        <linearGradient
          id="a"
          x1="136.1"
          y1="399"
          x2="136.1"
          y2="0"
          gradientTransform="matrix(1 0 0 -1 0 399)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ffd938" />
          <stop offset="1" stopColor="#ffae1a" />
        </linearGradient>
      </defs>
      <path fill="#222" d="M15.1 16.5h241v367h-241z" />
      <path
        d="M229.5 0H42.6C19.1 0 0 19.1 0 42.6v313.7c0 23.5 19.1 42.6 42.6 42.6h186.8c23.5 0 42.6-19.1 42.6-42.6V42.6C272 19 252.9 0 229.4 0ZM55.3 373.5c-16.2 0-29.4-13.2-29.4-29.4s13.2-29.4 29.4-29.4 29.4 13.2 29.4 29.4-13.2 29.4-29.4 29.4Zm-19.1-78.9c-5.5 0-9.9-4.3-10.2-9.7h-.1V173.6c0-16.2 13.2-29.4 29.4-29.4s29.4 13.2 29.4 29.4S71.5 203 55.3 203s-14.4-2.9-19.6-7.6v73.8c.2 0 .3-.1.5-.1h69.9c.2 0 .3 0 .5.1v-95.5c0-16.2 13.2-29.4 29.4-29.4s29.4 13.2 29.4 29.4-13.2 29.4-29.4 29.4-14.4-2.9-19.6-7.6V285h-.1c-.3 5.4-4.7 9.7-10.2 9.7H36.2Zm210-55.7v105.2c0 16.2-13.2 29.4-29.4 29.4s-29.4-13.2-29.4-29.4 13.2-29.4 29.4-29.4 14.4 2.9 19.6 7.6v-70.7c-.2 0-.3.1-.5.1H166c-.2 0-.3 0-.5-.1v92.5c0 16.2-13.2 29.4-29.4 29.4s-29.4-13.2-29.4-29.4 13.2-29.4 29.4-29.4 14.4 2.9 19.6 7.6v-85.9c0-5.7 4.6-10.3 10.3-10.3h69.9c5.7 0 10.3 4.6 10.3 10.3v2.5Zm-29.4-36c-16.2 0-29.4-13.2-29.4-29.4s13.2-29.4 29.4-29.4 29.4 13.2 29.4 29.4-13.2 29.4-29.4 29.4Zm29.4-110.8c0 14.5-11.7 26.2-26.2 26.2H52.2c-14.5 0-26.2-11.7-26.2-26.2V53.4c0-14.5 11.7-26.2 26.2-26.2H220c14.5 0 26.2 11.7 26.2 26.2v38.7Z"
        fill="url(#a)"
      />
    </svg>
  );
};
