import { Link } from "react-router-dom";
import { Icon } from "@phosphor-icons/react";

interface HomeButtonProps {
  label: string;
  IconClass?: Icon;
  description?: string;
  to: string;
}

export const HomeButton: React.FC<HomeButtonProps> = ({
  to,
  label,
  description,
  IconClass,
}) => {
  return (
    <Link
      to={to}
      className="border dark:bg-white bg-opacity-5 dark:bg-opacity-5 dark:border-zinc-500 hover:ring-2 dark:hover:ring-white transition-all duration-200 p-4 rounded-md"
    >
      <div className="flex gap-2 items-center">
        {IconClass && <IconClass />}
        <h2>{label}</h2>
      </div>
      {description && <p className="text-sm mt-2 dark:text-zinc-400">{description}</p>}
    </Link>
  );
};
